@import "/src/assets/scss/variables";

:root {
  --primary-color: #{$primary-color};
  --primary-color-90: #{$primary-color}e6;
  --primary-color-80: #{$primary-color}cc;
  --primary-color-70: #{$primary-color}b3;
  --primary-color-60: #{$primary-color}99;
  --primary-color-50: #{$primary-color}80;
  --primary-color-40: #{$primary-color}66;
  --primary-color-30: #{$primary-color}4d;
  --primary-color-20: #{$primary-color}33;
  --primary-color-10: #{$primary-color}1a;
  --primary-color-5: #{$primary-color}0D;
}

@font-face {
  font-family: sf pro display;
  font-style: normal;
  font-weight: 400;
  src: url("../font/SFPRODISPLAYREGULAR.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 100;
  src: url("../font/SFPRODISPLAYULTRALIGHTITALIC.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 200;
  src: url("../font/SFPRODISPLAYTHINITALIC.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 300;
  src: url("../font/SFPRODISPLAYLIGHTITALIC.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: normal;
  font-weight: 500;
  src: url("../font/SFPRODISPLAYMEDIUM.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 600;
  src: url("../font/SFPRODISPLAYSEMIBOLDITALIC.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: normal;
  font-weight: 700;
  src: url("../font/SFPRODISPLAYBOLD.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 800;
  src: url("../font/SFPRODISPLAYHEAVYITALIC.woff");
}

@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 900;
  src: url("../font/SFPRODISPLAYBLACKITALIC.woff");
}

body {
  font-family: "SF Pro Display";
  font-style: normal;
}

.container {
  max-width: 1137px;
}

.mat-input-element {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid $text-color-2 !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 15px !important;
  padding-left: 0px !important;
  border-radius: 0px !important;
  color: $text-color-2 !important;
  width: 100% !important;
  height: 30px !important;
}

.mat-focused {
  display: none;
}

.back-button a {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  margin-top: -5px;
  color: $text-color-2;
}

.back-button a:hover,
img:hover {
  color: $background-color-3;
}

/****  floating-Lable style start ****/
.floating-form {
  width: 100%;
}

.floating-field {
  position: relative;
  margin-top: 2em;
  margin-bottom: 20px;
}

.floating-input,
.floating-select {
  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #757575;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
  border-bottom: 2px solid $text-color-2;
}

.floating-field > label {
  color: $text-color-2;
  position: absolute;
  pointer-events: none;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-input:focus ~ label,
.floating-input:not(:placeholder-shown) ~ label {
  top: -18px;
  color: $text-color-2;
}

.floating-input:-webkit-autofill + label {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.floating-select:focus ~ label,
.floating-select:not([value=""]):valid ~ label {
  top: -18px;
  color: $text-color-2;
}

/* active state */
.floating-input:focus ~ .bar:before,
.floating-input:focus ~ .bar:after,
.floating-select:focus ~ .bar:before,
.floating-select:focus ~ .bar:after {
  width: 50%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.floating-textarea {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
}

/* highlighter */
.highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.floating-input:focus ~ .highlight,
.floating-select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* animation */
@-webkit-keyframes inputHighlighter {
  from {
    background: transparent;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: transparent;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: transparent;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.input-with-icon .icon {
  position: absolute;
  left: auto;
  right: 0px;
  top: 7px;
  cursor: pointer;
  color: $text-color-2;
}

.input-with-icon .icon:hover {
  color: $background-color-3;
}

/****  floating-Lable style end ****/
.page-container {
  min-height: auto !important;
  margin: 0px 17%;

  @media screen and (max-width: 576px) {
    margin: 0 12px;
  }
}

.btn-verify {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  height: 50px !important;
  border-radius: 8px !important;
  &:hover {
    background-color: $background-color-1 !important;
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100vh !important;
}

.muted-text {
  color: $text-color-2 !important;
}

.no-text-deco {
  text-decoration: none !important;
}

.text-blue {
  color: $background-color-3 !important;
}

.hover-blue:hover {
  color: $background-color-3 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.close {
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid $border-color-1;
  border-radius: 8px;
  &:hover {
    border: 1px solid $text-color-2;
  }
}

.mat-form-field {
  font-family: "SF Pro Display";
  font-style: normal;
}

.mat-form-field-infix {
  padding: 0px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px !important;
}

div[role="alert"] {
  color: white;
}

.display-none {
  display: none;
}

.bi-star-fill {
  color: #ffa412;
}

.privacy-level--public {
  background: url(/assets/img/public-black.png);
  width: 20px;
  height: 20px;
}

.privacy-level--team {
  background-image: url(/assets/img/team-black.png);
  width: 20px;
  height: 20px;
}

.privacy-level--private {
  background-image: url(/assets/img/private-black.png);
  width: 20px;
  height: 20px;
}

.mat-mdc-dialog-surface-pwa {
  .mdc-dialog__surface {
    overflow: hidden !important;
  }
}

.border-red {
  border: 1px solid red;
}
